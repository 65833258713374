import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SidenavItem } from './sidenav-item/sidenav-item.interface';
import { SidenavService } from './sidenav.service';
import { ThemeService } from '../../../@prep/services/theme.service';

import { GlobalUser } from '../model/global-user';
import { Subscription } from 'rxjs';
import { PlatformGlobalService } from '../service/platform-global.service';
import { CurrentUserService } from '../../pages/authentication/service/current-user.service';
import { DashboardService } from 'src/app/pages/dashboard/service/dashboard.service';
// import { UserIdleService } from 'angular-user-idle';

// import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  selector: 'prep-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  providers: [CurrentUserService],
})
export class SidenavComponent implements OnInit, OnDestroy {
  account: GlobalUser = new GlobalUser();
  userSub: Subscription;
  account_group = [];
  account_user_name: string;
  account_user_email: string;
  isContinualEngine: boolean = false;

  public Current_User: GlobalUser;

  accountOrg: string;

  sidenavUserVisible$ = this.themeService.config$.pipe(
    map((config) => config.sidenavUserVisible)
  );

  @Input()
  @HostBinding('class.collapsed')
  collapsed: boolean;

  @Input()
  @HostBinding('class.expanded')
  expanded: boolean;

  items$: Observable<SidenavItem[]>;

  // IDLE
  idleNum?: number;
  timeout?: number;
  ping?: number;
  lastPing = null;
  isWatching?: boolean;
  isTimer?: boolean;
  timeIsUp?: boolean;
  timerCount?: number;

  idleState = 'Not started.';
  timedOut = false;

  private timerStartSubscription!: Subscription;
  private timeoutSubscription!: Subscription;
  private pingSubscription!: Subscription;

  constructor(
    private router: Router,
    private platformglobalService: PlatformGlobalService,
    private sidenavService: SidenavService,
    private currentUserService: CurrentUserService,
    private themeService: ThemeService,
    private dashboardService: DashboardService,
    // private userIdleService: UserIdleService,
    // private idle: Idle,
    private keepalive: Keepalive,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (localStorage.getItem('token') && localStorage.getItem('account')) {
      this.platformglobalService.me = JSON.parse(
        localStorage.getItem('account')
      );
    }

    this.items$ = this.sidenavService.items$.pipe(
      map((items: SidenavItem[]) =>
        this.sidenavService.sortRecursive(items, 'position')
      )
    );

    this.userSub = this.platformglobalService.user.subscribe((me) => {
      console.log('me', me);
      this.account = me;
      this.account_user_name =
        this.account.first_name + ' ' + this.account.last_name;
      this.account_user_email = this.account.email;
    });

    this.currentUserService.GetCurrentUser().subscribe(
      (response) => {
        this.Current_User = response;
        this.platformglobalService.setCUserInfo = response;
        this.timeout = 10;
        this.ping = 10;
        // if (this.Current_User.idle_time) {
        //   this.idleNum = this.Current_User.idle_time;
        //   // sets an idle timeout of 30 seconds, for testing purposes.
        //   this.idle.setIdle(this.idleNum);
        //   // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        //   this.idle.setTimeout(10);
        //   // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        //   this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        //   this.idle.onIdleEnd.subscribe(() => {
        //     this.idleState = 'No longer idle.';
        //     console.log('Idle State end', this.idleState);
        //   });
        //   this.idle.onTimeout.subscribe(() => {
        //     this.idleState = 'Timed out!';
        //     console.log('Idle State on time out', this.idleState);
        //     this.timedOut = true;
        //     this.logoutClicked();
        //   });
        //   this.idle.onIdleStart.subscribe(() => {
        //     this.idleState = "You've gone idle!";
        //     console.log('Idle State STart', this.idleState);
        //   });
        //   this.idle.onTimeoutWarning.subscribe((countdown) => {
        //     this.idleState = 'You will time out in ' + countdown + ' seconds!';
        //     console.log('Idle State timeout wrning', this.idleState);
        //   });
        //   // sets the ping interval to 15 seconds
        //   this.keepalive.interval(15);
        //   this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
        //   this.reset();
        // }

        const accEmail = this.Current_User.username;
        const accDomain = accEmail.replace(/.*@/, '');
        console.log('Domain Info', accDomain);
        if (accDomain === 'continualengine.com') {
          this.isContinualEngine = true;
        } else {
          this.isContinualEngine = false;
        }

        if (this.Current_User.configs.API_tool === false) {
          if (this.Current_User.configs.enable_manage_accounts === true) {
            this.sidenavService.addItems([
              {
                name: 'Dashboard',
                routeOrFunction: '/dashboard',
                icon: 'dashboard',
                position: 10,
                pathMatchExact: true,
              },
              {
                name: 'Upload File',
                routeOrFunction: '/file-upload',
                icon: 'upload_file',
                position: 15,
              },
              {
                name: 'MANAGE ACCOUNT',
                position: 30,
                type: 'subheading',
                customClass: 'first-subheading',
              },
              {
                name: 'Users',
                routeOrFunction: '/users',
                icon: 'manage_accounts',
                position: 35,
              },
            ]);
          } else {
            this.sidenavService.addItems([
              {
                name: 'Dashboard',
                routeOrFunction: '/dashboard',
                icon: 'dashboard',
                position: 10,
                pathMatchExact: true,
              },
              {
                name: 'Upload File',
                routeOrFunction: '/file-upload',
                icon: 'upload_file',
                position: 15,
              },
            ]);
          }
          // if (this.sidenavService.items.length > 0) {
          //   this.sidenavService.items.forEach((element, index) => {
          //     if (
          //       element.name === 'API Usage' ||
          //       element.name === 'Report'
          //     ) {
          //       this.sidenavService.items.splice(index, 1);
          //     }
          //   });
          // }
          if (this.Current_User.configs.rsp) {
            if (this.Current_User.configs.rsp === true) {
              this.sidenavService.addItems([
                {
                  name: 'REMEDIATION SERVICES',
                  position: 55,
                  type: 'subheading',
                  customClass: 'first-subheading',
                },
                {
                  name: 'Orders',
                  routeOrFunction: '/orders',
                  icon: 'receipt_long',
                  position: 60,
                },
              ]);
            }
            if (this.Current_User.configs.rsp_admin === true) {
              if (this.Current_User.configs.rsp_pay === true) {
                this.sidenavService.addItems([
                  {
                    name: 'Transactions',
                    routeOrFunction: '/RSP-Dept-Transaction',
                    icon: 'paid',
                    position: 65,
                  },
                ]);
              } else {
                this.sidenavService.addItems([
                  {
                    name: 'Transactions',
                    routeOrFunction: '/transcations',
                    icon: 'paid',
                    position: 65,
                  },
                ]);
              }
            }
          }
        } else {
          this.sidenavService.addItems([
            {
              name: 'Dashboard',
              routeOrFunction: '/dashboard',
              icon: 'dashboard',
              position: 10,
              pathMatchExact: true,
            },
            {
              name: 'Upload File',
              routeOrFunction: '/file-upload',
              icon: 'upload_file',
              position: 15,
            },
            {
              name: 'Exports',
              routeOrFunction: '/exports',
              icon: 'download_for_offline',
              position: 20,
            },
          ]);

          if (this.sidenavService.items.length > 0) {
            this.sidenavService.items.forEach((element, index) => {
              if (element.name === 'Users' || element.name === 'API Usage') {
                this.sidenavService.items.splice(index, 1);
              }
            });
          }

          if (this.Current_User.is_superuser === true) {
            this.sidenavService.addItems([
              {
                name: 'Report',
                routeOrFunction: '/report',
                icon: 'analytics',
                position: 25,
              },
            ]);

            if (this.Current_User.configs.enable_manage_accounts === true) {
              console.log('I Can Insde');
              if (this.Current_User.ceadmin === true) {
                this.sidenavService.addItems([
                  {
                    name: 'REMEDIATION TOOL',
                    position: 30,
                    type: 'subheading',
                    customClass: 'first-subheading',
                  },
                  {
                    name: 'Account Config',
                    routeOrFunction: '/account-config',
                    icon: 'manage_accounts',
                    position: 35,
                  },
                  {
                    name: 'File Uploads Usage',
                    routeOrFunction: '/file-uploads-count',
                    icon: 'analytics',
                    position: 45,
                  },
                  {
                    name: 'API Usage',
                    routeOrFunction: '/api-usage',
                    icon: 'data_usage',
                    position: 50,
                  },
                ]);
              } else {
                this.sidenavService.addItems([
                  {
                    name: 'REMEDIATION TOOL',
                    position: 30,
                    type: 'subheading',
                    customClass: 'first-subheading',
                  },
                  {
                    name: 'Users',
                    routeOrFunction: '/users',
                    icon: 'person',
                    position: 35,
                  },
                  {
                    name: 'File Uploads Usage',
                    routeOrFunction: '/file-uploads-count',
                    icon: 'analytics',
                    position: 45,
                  },
                  {
                    name: 'API Usage',
                    routeOrFunction: '/api-usage',
                    icon: 'data_usage',
                    position: 50,
                  },
                ]);
              }
            } else {
              this.sidenavService.addItems([
                {
                  name: 'REMEDIATION TOOL',
                  position: 30,
                  type: 'subheading',
                  customClass: 'first-subheading',
                },
                {
                  name: 'File Uploads Usage',
                  routeOrFunction: '/file-uploads-count',
                  icon: 'analytics',
                  position: 45,
                },
                {
                  name: 'API Usage',
                  routeOrFunction: '/api-usage',
                  icon: 'data_usage',
                  position: 50,
                },
              ]);
            }
            if (this.Current_User.configs.rsp) {
              if (this.Current_User.configs.rsp === true) {
                this.sidenavService.addItems([
                  {
                    name: 'REMEDIATION SERVICES',
                    position: 55,
                    type: 'subheading',
                    customClass: 'first-subheading',
                  },
                  {
                    name: 'Orders',
                    routeOrFunction: '/orders',
                    icon: 'receipt_long',
                    position: 60,
                  },
                ]);
              }
              if (this.Current_User.configs.rsp_admin === true) {
                if (this.Current_User.configs.rsp_pay === true) {
                  this.sidenavService.addItems([
                    {
                      name: 'Transactions',
                      routeOrFunction: '/RSP-Dept-Transaction',
                      icon: 'paid',
                      position: 65,
                    },
                  ]);
                } else {
                  this.sidenavService.addItems([
                    {
                      name: 'Transactions',
                      routeOrFunction: '/transcations',
                      icon: 'paid',
                      position: 65,
                    },
                  ]);
                }
              }
            }

            if (this.Current_User.configs.d2l === true) {
              this.sidenavService.addItems([
                {
                  name: 'D2L INTERGATION',
                  position: 100,
                  type: 'subheading',
                  customClass: 'first-subheading',
                },
                {
                  name: 'D2L Files',
                  routeOrFunction: '/d2l-lms-files',
                  icon: 'folder',
                  position: 105,
                },
              ]);
            }

            if (
              this.isContinualEngine === true &&
              this.account.ceadmin === true
            ) {
              this.sidenavService.addItems([
                {
                  name: 'REMEDIATION SERVICE ADMIN',
                  position: 90,
                  type: 'subheading',
                  customClass: 'first-subheading',
                },
                {
                  name: 'CE Orders',
                  routeOrFunction: '/RSP-CE-Orders',
                  icon: 'receipt',
                  position: 95,
                },
              ]);
            }
          } else {
            if (this.Current_User.configs.rsp) {
              if (this.Current_User.configs.rsp === true) {
                this.sidenavService.addItems([
                  {
                    name: 'REMEDIATION SERVICES',
                    position: 55,
                    type: 'subheading',
                    customClass: 'first-subheading',
                  },
                  {
                    name: 'Orders',
                    routeOrFunction: '/orders',
                    icon: 'receipt_long',
                    position: 60,
                  },
                ]);
              }
              if (this.Current_User.configs.rsp_admin === true) {
                if (this.Current_User.configs.rsp_pay === true) {
                  this.sidenavService.addItems([
                    {
                      name: 'Transactions',
                      routeOrFunction: '/RSP-Dept-Transaction',
                      icon: 'paid',
                      position: 65,
                    },
                  ]);
                } else {
                  this.sidenavService.addItems([
                    {
                      name: 'Transactions',
                      routeOrFunction: '/transcations',
                      icon: 'paid',
                      position: 65,
                    },
                  ]);
                }
              }
            }
            if (this.Current_User.configs.d2l === true) {
              this.sidenavService.addItems([
                {
                  name: 'D2L INTERGATION',
                  position: 100,
                  type: 'subheading',
                  customClass: 'first-subheading',
                },
                {
                  name: 'D2L Files',
                  routeOrFunction: '/d2l-lms-files',
                  icon: 'folder',
                  position: 105,
                },
              ]);
            }
          }
        }
      },
      (error) => {
        this.logoutClicked();
      }
    );
    this.items$ = this.sidenavService.items$.pipe(
      map((items: SidenavItem[]) =>
        this.sidenavService.sortRecursive(items, 'position')
      )
    );
  }

  logoutClicked() {
    let sendUserInfo = {
      username: this.account_user_email,
    };
    this.dashboardService.logoutCuncurrentUser(sendUserInfo).subscribe(
      (response) => {
        if (response.msg) {
          localStorage.removeItem('token');
          localStorage.removeItem('account');
          this.sidenavService.items = [];
          this.router.navigate(['/login']);
        }
      },
      (error) => {
        console.log('error', error);
        localStorage.removeItem('token');
        localStorage.removeItem('account');
        this.sidenavService.items = [];
        this.router.navigate(['/login']);
      }
    );
  }

  profile() {
    console.log('welcome to profile');
    this.router.navigate(['/profile']);
  }
  toggleCollapsed() {
    this.sidenavService.toggleCollapsed();
  }

  @HostListener('mouseenter')
  @HostListener('touchenter')
  onMouseEnter() {
    this.sidenavService.setExpanded(true);
  }

  @HostListener('mouseleave')
  @HostListener('touchleave')
  onMouseLeave() {
    this.sidenavService.setExpanded(false);
  }

  ngOnDestroy() {}

  // reset() {
  //   this.idle.watch();
  //   this.idleState = 'Started.';
  //   this.timedOut = false;
  // }

  // onStartWatching() {
  //   console.log('IDLE TIMEOUT', this.idle, this.timeout, this.ping);
  //   this.isWatching = true;
  //   this.timerCount = this.timeout;
  //   this.userIdleService.setConfigValues({
  //     idle: this.idle,
  //     timeout: this.timeout,
  //     ping: this.ping,
  //   });

  //   // Start watching for user inactivity.
  //   this.userIdleService.startWatching();

  //   // Start watching when user idle is starting.
  //   this.timerStartSubscription = this.userIdleService
  //     .onTimerStart()
  //     .pipe(
  //       tap(() => {
  //         this.isTimer = true;
  //         // console.log('TIMER ===>', this.isTimer);
  //       })
  //     )
  //     .subscribe((count) => {
  //       this.timerCount = count;
  //       console.log('TIMER COUNT ====>', this.timerCount);
  //     });

  //   // Start watch when time is up.
  //   this.timeoutSubscription = this.userIdleService
  //     .onTimeout()
  //     .subscribe(() => {
  //       this.timeIsUp = true;
  //       if (this.timeIsUp === true) {
  //         this.onStopWatching();
  //         this.logoutClicked();
  //       }
  //       console.log('TIME UP ====>', this.timeIsUp);
  //     });
  // }

  // onStopWatching() {
  //   this.userIdleService.stopWatching();
  //   this.timerStartSubscription.unsubscribe();
  //   this.timeoutSubscription.unsubscribe();
  //   this.pingSubscription.unsubscribe();
  //   this.isWatching = false;
  //   this.isTimer = false;
  //   this.timeIsUp = false;
  //   this.lastPing = null;
  // }

  // onStopTimer() {
  //   this.userIdleService.stopTimer();
  //   this.isTimer = false;
  // }

  // onResetTimer() {
  //   this.userIdleService.resetTimer();
  //   this.isTimer = false;
  //   this.timeIsUp = false;
  // }

  // onIdleKeyup() {
  //   // this.userIdleService.chemit({ idle: this.idle });
  // }

  // onTimeoutKeyup() {
  //   // this.changeIdleValues.emit({ timeout: this.timeout });
  // }

  // onPingKeyup() {
  //   // this.changeIdleValues.emit({ ping: this.ping });
  // }
}
