import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
// Animation
import { fadeInUpAnimation } from '../../../../@prep/animations/fade-in-up.animation';
import { fadeInRightAnimation } from '../../../../@prep/animations/fade-in-right.animation';
// Browser Fingerprint to generate Unique ID
import Fingerprint from 'fingerprintjs2';
// Freshwork Service for Help widget Open / Close
// import { NgxFreshworkWidgetService } from '../../../widget/ngx-freshwork-widget/ngx-freshwork-widget.service';
// Services
import { PlatformUserService } from '../service/platform-user.service';
import { PlatformGlobalService } from '../../../layout/service/platform-global.service';
import { CurrentUserService } from '../service/current-user.service';

@Component({
  selector: 'prep-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [PlatformUserService, PlatformGlobalService],
  animations: [fadeInUpAnimation, fadeInRightAnimation],
})
export class LoginComponent implements OnInit {
  // Analytic to save logged in user for the first time
  // analytics = (window.analytics = window.analytics || []);
  // User Login
  UserLoginFormGroup: FormGroup;
  // To extract device unique ID
  resultFingerPrint: any;
  // loading Spinner
  is_loading = false;

  // Password Toggle Visibility
  inputType = 'password';
  visible = false;

  // Toggle Help Widget
  helpWidgetBool = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private snackbar: MatSnackBar,
    // private ngxFreshworkWidgetService: NgxFreshworkWidgetService,
    private platformuserService: PlatformUserService,
    private platformGlobalService: PlatformGlobalService
  ) {
    this.UserLoginFormGroup = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      id: '',
    });
    // Browser FingerPrint
    this.resultFingerPrint = async () => {
      const secure = await new Promise((resolve) => {
        Fingerprint.getV18((result, components) => resolve(result));
      });
      return secure;
    };
    this.resultFingerPrint().then((result) => {
      this.resultFingerPrint = result;
      this.UserLoginFormGroup.patchValue({
        id: result,
      });
    });
    console.log('Login Form', this.UserLoginFormGroup.value);
  }

  ngOnInit() {
    // Check user Offline / Online
    this.platformuserService.createOnline$().subscribe((isOnline) => {
      console.log('online======', isOnline);
      if (!isOnline) {
        this.snackbar.open(
          'Error:Please check Internet  Connection!!',
          'OK THANKS',
          {
            duration: 10000,
          }
        );
      }
    });
    // Login User If User already Logged In
    if (localStorage.getItem('token') && localStorage.getItem('account')) {
      this.platformGlobalService.me = JSON.parse(
        localStorage.getItem('account')
      );
      this.router.navigate(['dashboard']);
    }
    this.is_loading = false;
  }

  HelpClick() {
    // this.helpWidgetBool = !this.helpWidgetBool;
    // if (this.helpWidgetBool) {
    //   this.ngxFreshworkWidgetService.FreshworksWidget('open');
    // } else {
    //   this.ngxFreshworkWidgetService.FreshworksWidget('hide');
    // }
  }

  send() {
    this.is_loading = true;
    console.log(this.UserLoginFormGroup.value);
    this.platformuserService.loginUser(this.UserLoginFormGroup.value).subscribe(
      (response) => {
        this.is_loading = false;
        localStorage.setItem('token', response['token']);
        this.platformGlobalService.me = response['user'];
        this.router.navigate(['dashboard']);
      },
      (error) => {
        console.log('error', error);
        if (error.status === 400) {
          this.is_loading = false;
          this.snackbar.open('Incorrect Email Id or Password!', 'OK THANKS', {
            duration: 1500,
          });
          this.router.navigate(['/login']);
        } else if (error.status === 401) {
          this.is_loading = false;
          this.snackbar.open(
            'User Allowed to Login on only one Device',
            'OK THANKS',
            {
              duration: 1500,
            }
          );
          this.router.navigate(['login']);
        } else if (error.status === 417) {
          this.is_loading = false;
          console.log('error info cuncurrent user', error.error.msg);
          if (error.error.msg) {
            this.snackbar.open(error.error.msg, 'OK THANKS', {
              duration: 10000,
            });
          }
        } else {
          this.is_loading = false;
          this.router.navigate(['login']);
        }
      }
    );
    this.platformuserService.createOnline$().subscribe((isOnline) => {
      console.log('online======', isOnline);
      if (!isOnline) {
        this.snackbar.open(
          'Error:Please check Internet  Connection!!',
          'OK THANKS',
          {
            duration: 10000,
          }
        );
      }
    });
  }

  // Password Toggle Visibility
  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }
}
