import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment.prod';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });
  baseUrl: string = environment.backend;

  constructor(private http: HttpClient) {}
  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }
  GetCurrentUser(): Observable<any> {
    return this.http.get(
      this.baseUrl + 'accounts/current-user/',
      this.getAuthHeaders()
    );
  }

  ChangeCurrentUserPwd(fg): Observable<any> {
    return this.http.post(
      this.baseUrl + 'accounts/change-password/',
      fg,
      this.getAuthHeaders()
    );
  }
  forgotPwd(fg): Observable<any> {
    return this.http.post(this.baseUrl + 'api/password_reset/', fg);
  }

  postSignUp(fg): Observable<any> {
    return this.http.post(this.baseUrl + 'llti/create_user/', fg);
  }

  configForm(fg): Observable<any> {
    return this.http.post(
      this.baseUrl + 'llti/config/',
      fg,
      this.getAuthHeaders()
    );
  }

  getD2Lconfig(): Observable<any> {
    return this.http.get(
      this.baseUrl + 'llti/get_client_config/',
      this.getAuthHeaders()
    );
  }

  private getAuthHeaders() {
    const token = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({ Authorization: 'Token ' + token });

    return { headers: httpHeaders };
  }
}
