import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/authentication/login/login.module').then(
        (m) => m.LoginModule
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import(
        './pages/authentication/forgot-password/forgot-password.module'
      ).then((m) => m.ForgotPasswordModule),
  },
  {
    path: 'Y2hhbmdlX3Bhc3N3b3Jk/:userToken/:alphanumeric',
    loadChildren: () =>
      import(
        './pages/authentication/reset-password/reset-password.module'
      ).then((m) => m.ResetPasswordModule),
  },
  {
    path: 'collaborate/:id',
    loadChildren: () =>
      import('./pages/collaborate/collaborate.module').then(
        (m) => m.CollaborateModule
      ),
  },
  {
    path: 'pdf-remediation/:id',
    loadChildren: () =>
      import('./pages/pdf-remediation/pdf-remediation.module').then(
        (m) => m.PdfRemediationModule
      ),
  },
  {
    path: 'd2l-config-form',
    loadChildren: () =>
      import('./pages/authentication/config-form/config-form.module').then(
        (m) => m.ConfigFormModule
      ),
  },
  {
    path: 'sign-up',
    loadChildren: () =>
      import('./pages/authentication/sign-up/sign-up.module').then(
        (m) => m.SignUpModule
      ),
  },
  {
    path: 'trns/:id',
    loadChildren: () =>
      import('./pages/success-page/success-page.module').then(
        (m) => m.SuccessPageModule
      ),
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        pathMatch: 'full',
      },
      {
        path: 'file-upload',
        loadChildren: () =>
          import('./pages/file-upload/file-upload.module').then(
            (m) => m.FileUploadModule1
          ),
      },
      {
        path: 'api-usage',
        loadChildren: () =>
          import('./pages/api-usage/api-usage.module').then(
            (m) => m.ApiUsageModule
          ),
      },
      {
        path: 'file-uploads-count',
        loadChildren: () =>
          import('./pages/file-upload-count/file-upload-count.module').then(
            (m) => m.FileUploadCountModule
          ),
      },
      {
        path: 'exports',
        loadChildren: () =>
          import('./pages/file-export/file-export.module').then(
            (m) => m.FileExportModule
          ),
      },
      {
        path: 'RSP-CE-Orders',
        loadChildren: () =>
          import('./pages/rsp-admin/rsp-ce-orders/rsp-ce-orders.module').then(
            (m) => m.RspCeOrdersModule
          ),
      },
      {
        path: 'RSP-Dept-Transaction',
        loadChildren: () =>
          import(
            './pages/rsp-department-transaction/rsp-department-transaction.module'
          ).then((m) => m.RspDepartmentTransactionModule),
      },
      {
        path: 'RSP-Dept-Team',
        loadChildren: () =>
          import('./pages/rsp-department-user/rsp-department-user.module').then(
            (m) => m.RspDepartmentUserModule
          ),
      },
      {
        path: 'Department',
        loadChildren: () =>
          import('./pages/rsp-org-depatments/rsp-org-depatments.module').then(
            (m) => m.RspOrgDepatmentsModule
          ),
      },
      {
        path: 'RSP-CE-Price-Config',
        loadChildren: () =>
          import(
            './pages/rsp-admin/rsp-ce-price-config-client/rsp-ce-price-config-client.module'
          ).then((m) => m.RspCePriceConfigClientModule),
      },
      {
        path: 'RSP-Client-Deleted-Orders',
        loadChildren: () =>
          import(
            './pages/rsp-admin/rsp-client-deleted-orders/rsp-client-deleted-orders.module'
          ).then((m) => m.RspClientDeletedOrdersModule),
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./pages/rsp-orders/rsp-orders.module').then(
            (m) => m.RspOrdersModule
          ),
      },
      {
        path: 'transcations',
        loadChildren: () =>
          import('./pages/rsp-transaction/rsp-transaction.module').then(
            (m) => m.RspTransactionModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./pages/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'account-config',
        loadChildren: () =>
          import('./pages/user-admin/user-admin.module').then(
            (m) => m.UserAdminModule
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./pages/user-profile/user-profile.module').then(
            (m) => m.UserProfileModule
          ),
      },
      {
        path: 'resources',
        loadChildren: () =>
          import('./pages/user-resources/user-resources.module').then(
            (m) => m.UserResourcesModule
          ),
      },
      {
        path: 'report',
        loadChildren: () =>
          import('./pages/generate-report/generate-report.module').then(
            (m) => m.GenerateReportModule
          ),
      },
      {
        path: 'new-tree',
        loadChildren: () =>
          import('./pages/k-tree/k-tree.module').then((m) => m.KTreeModule),
      },
      {
        path: 'd2l-lms-files',
        loadChildren: () =>
          import('./pages/dl-lms/dl-lms.module').then((m) => m.DlLmsModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
